import React, { useRef} from 'react';
import { BsX } from "react-icons/bs";
import './AddTable.css';

export default function AddTable({ setDisplay, addTable }) {

    const inputRef = useRef("");


    const handleSubmit = () => {

        if (inputRef.current.value.trim() === "") {
            window.alert("Error: Restaurant Name Cannot Be Empty");
        } else {

            addTable(inputRef.current.value);
            setDisplay(false);
        }
    }

    return (
        <div className="addTableContainer">

                <div className="addTableTitle">Add Table</div>
                <button className="addTableEsc" onClick={() => setDisplay(false)}><BsX size={20} className="addTableEscIcon"/></button>


                <div className="addTableName">Enter Table Name</div>
                <input type="text" ref={inputRef} className="addTableInput"></input>
                <button className="addTableSubmitButton" onClick={() => handleSubmit()}>+</button>

        </div>
    );

}

