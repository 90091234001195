import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import './RestaurantButton.css';
import { BsThreeDots } from 'react-icons/bs';

export default function RestaurantButton({ id, name, updateRestaurants }) {
    const [displayDropdown, setDisplayDropdown] = useState(false);
    const { user, isAuthenticated } = useAuth0();
    const dropdown = useRef();
    const deleteButton = useRef();
    const API_URL = process.env.REACT_APP_AWS_BACKEND_URL;

    const handleClick = () => {
        window.location = `/adminPage/${id}`;
    }

    const handleClickDropdown = (e) => {
        e.stopPropagation();
        setDisplayDropdown(!displayDropdown);
    }

    const handleDeleteRestaurant = (e) => {
        e.stopPropagation();

        if (isAuthenticated) {

            const info = {
                'orgID': user.sub,
                'restID': id
            }

            console.log(info);
            axios.post(`${API_URL}organizations/deleteRestaurant`, info)
                .then(res => {
                    console.log(res);
                    setDisplayDropdown(!displayDropdown);
                    updateRestaurants();
                    document.removeEventListener('mousedown', handleClickOutside);
                })
                .catch(err => console.log(err));
        }

    }

    const handleClickOutside = useCallback((e) => {
        if (displayDropdown && !dropdown.current.contains(e.target) && !deleteButton.current.contains(e.target)) {
            setDisplayDropdown(false);
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [displayDropdown])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    }, [dropdown, handleClickOutside]);

    return (
        <div className='restaurantButton' onClick={() => handleClick()}>
            <div className='restaurantButtonTopBar'>
                <div className='restaurantButtonDropDown' ref={dropdown} onClick={handleClickDropdown}><BsThreeDots size={20} /></div>
                {displayDropdown ?
                    <div className="restaurantButtonDropDownMenu">
                        <div className="restaurantButtonDropDownMenuItem" ref={deleteButton} onClick={handleDeleteRestaurant}>
                            Delete
                            </div>
                    </div> : ""}
            </div>

            <div className='restaurantButtonNameContainer'>
                <div className='restaurantButtonName'>{name}</div>
            </div>
        </div>
    )
}