import React, { useCallback, useEffect, useRef, useState } from 'react';
import './OrganizationPage.css'
import { ReactComponent as Loading } from '../Assets/Loading.svg';
import LogoutButton from '../Components/LogoutButton.js';
import RestaurantButton from '../Components/RestaurantButton';
import AddRestaurant from '../Components/AddRestaurant.js';
import FirstTimeLogin from '../Components/FirstTimeLogin.js';
import { useAuth0 } from '@auth0/auth0-react';
import { HiMenu } from 'react-icons/hi';
import axios from 'axios';

export default function OrganizationPage() {

    const [restaurants, setRestaurants] = useState([]);
    const [displayAddRestaurant, setDisplayAddRestaurant] = useState(false);
    const [newUser, setNewUser] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [displayDropdown, setDisplayDropdown] = useState(false);
    const [justClosedDropdown, setJustClosedDropdown] = useState(false);
    const dropdown = useRef();
    const dropdownButton = useRef();
    const { user, isAuthenticated } = useAuth0();
    const API_URL = process.env.REACT_APP_AWS_BACKEND_URL;


    useEffect(() => {
        if (isAuthenticated) {

            const req = {
                "orgID": user.sub
            }

            axios.post(`${API_URL}organizations/`, req)
                .then(res => {
                    if (!res.data) {
                        setNewUser(true);
                    }
                    else {
                        setNewUser(false);
                    }
                })
        }
    }, [isAuthenticated, user, API_URL])

    const updateRestaurants = useCallback(() => {
        if (isAuthenticated) {

            const info = {
                "orgID": user.sub
            }

            axios.post(`${API_URL}organizations/`, info)
                .then(res => { setRestaurants(res.data.restaurants); setIsLoading(false); })
                .catch(err => console.log(err));
        }
    }, [isAuthenticated, user, API_URL])

    useEffect(() => {
        updateRestaurants();
    }, [isAuthenticated, updateRestaurants]);

    const handleClickOutside = useCallback((e) => {
        if (displayDropdown && dropdown.current && (!dropdown.current.contains(e.target))) {
            setDisplayDropdown(false);
            document.removeEventListener('mousedown', handleClickOutside);
            if (dropdownButton.current.contains(e.target))
                setJustClosedDropdown(true);
        }
    }, [displayDropdown])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    }, [dropdown, handleClickOutside]);

    const setDisplay = (val) => {
        setDisplayAddRestaurant(val);
    }

    const completeNewLogin = () => {
        setNewUser(false);
    }

    const handleDisplayDropdown = (val) => {

        if (justClosedDropdown)
            setJustClosedDropdown(false);
        else
            setDisplayDropdown(val);

    }

    const handleAddRestaurantButton = () => {
        setDisplay(true);
        setDisplayDropdown(false);
    }

    return newUser ? (<FirstTimeLogin completeNewLogin={completeNewLogin} />) : (
        <div>
            {displayAddRestaurant ? <AddRestaurant setDisplay={setDisplay} updateRestaurants={updateRestaurants} /> : ""}
            <div className="organizationPageContainer">

                <div className='headerContainer'>
                    <button className='organizationPageDropdownButton' ref={dropdownButton} onClick={() => handleDisplayDropdown(!displayDropdown)}><HiMenu size={30} /></button>
                    {displayDropdown ?
                        <div className='organizationPageDropdownContainer' ref={dropdown}>
                            <div className='organizationPageDropdownItem' onClick={handleAddRestaurantButton}><span className='organizationPageDropdownItemText'>Add Restaurant</span></div>
                        </div>
                        : ""}
                    <div className='organizationTitle'><span className="Light">Food</span><span className="Heavy">Flag</span></div>
                    <LogoutButton className='logout' />
                </div>
                <div className='restaurantsContainer'>
                    {isLoading ? <Loading /> : restaurants.map((rest, i) => {
                        return (
                            <RestaurantButton name={rest.name} key={i} id={rest._id} updateRestaurants={updateRestaurants} />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

