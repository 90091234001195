import React from 'react';
import Login from './Pages/Login.js'
import RequestPage from './Pages/RequestPage.js';
import AdminPage from "./Pages/AdminPage.js";
import OrganizationPage from "./Pages/OrganizationPage.js";
import { BrowserRouter as Switch, Route } from "react-router-dom";

function App() {
  return (

        <Switch>
          <Route path="/" exact component={Login}/>
          <Route path="/requestPage" component={RequestPage}/>
          <Route path="/adminPage" component={AdminPage}/>
          <Route path="/organizationPage" component={OrganizationPage}/>
        </Switch>

  );
}

export default App;
