import React, {useRef}  from 'react';
import './Login.css';
import { BsPerson } from "react-icons/bs";
import Axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

export default function Login() {

    const textRef = useRef("");
    const { loginWithRedirect } = useAuth0();
    const API_URL = process.env.REACT_APP_AWS_BACKEND_URL;

    const handleGo = (e) => {

      if (textRef.current.value.length < 6) {
        window.alert("Invalid Code");
      } else {
        
        const code = { "code" : textRef.current.value}

        Axios.post(`${API_URL}codes/findCode/`, code)
          .then(res => {
            if (res.data)
              window.location = `/requestPage/${textRef.current.value}`;
            else
              window.alert("Invalid Code");
          })
        
      }
    };

    const handleAdmin = () => {
      loginWithRedirect();
    }

    return (
      <div className="Login-box">

        <button className = "adminButton" onClick={() => handleAdmin()}><BsPerson color="white" size={18} className='adminButtonIcon'/></button>
        <p className="loginTitle"><span className="Light">Food</span><span className="Heavy">Flag</span></p>
        <p className="Enter-text">Enter Table Code</p>

        <div className="Input">
          <input type="text" className="Input-box" maxLength="6" ref={textRef} />
        </div>

        <div className="Go">
          <button className="Go-button" onClick={() => handleGo()}>
            GO
          </button>
        </div>
      </div>
    );
}
