import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { BsX } from "react-icons/bs";
import './RequestPage.css';

export default function RequestPage() {
  const [code, setCode] = useState('');
  const [table, setTable] = useState('');
  const [restaurantID, setRestaurantID] = useState('');
  const [organzationID, setOrganizationID] = useState('');
  const API_URL = process.env.REACT_APP_AWS_BACKEND_URL;

  useEffect(() => {
    const curCode = window.location.pathname.substring(13)

    if (curCode === "")
      window.location = "/";
    else {

      const codeInfo = { "code" : curCode};

      Axios.post(`${API_URL}codes/findCode/`, codeInfo)
        .then(res => {
          if (res.data) {
            setCode(curCode);
            setTable(res.data.tableName);
            setRestaurantID(res.data.restaurantID);
            setOrganizationID(res.data.organizationID);
          }
          else
            window.location = "/";
        })
    }
  }, [API_URL]);

  // Don't know if I'll keep this, checks every ten seconds if code is still valid
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     Axios.get(`https://69j0p1umk4.execute-api.us-east-1.amazonaws.com/production/codes/${code}/findCode/`)
  //       .then(res => {
  //         if (!res.data)
  //           window.location = "/";
  //       })
  //   }, 10000);

  //   return () => clearInterval(interval);
  // })

  // const removeReq = (req) => {
  //   if (request.includes(req)) {
  //     const newReq = request.filter((val) => { return val !== req; });
  //     setRequest(newReq);
  //   }
  //   console.log(request);
  // }

  const onSubmit = () => {
    
    const codeInfo = { "code" : code};

    Axios.post(`${API_URL}codes/findCode/`, codeInfo)
      .then(res => {
        if (!res.data) {
          window.alert("Error: Code no longer valid");
          window.location = "/";
        }
        else {
          const reqInfo =
          {
            "orgID": organzationID,
            "restID": restaurantID,
            "name": table
          }

          Axios.post(`${API_URL}organizations/addRequest/`, reqInfo)
            .then(res => {
              console.log(res.data);
              window.alert("Request Sent");
            })
            .catch(err => console.log(err));
        }
      });
  }

  const handleEsc = (e) => {
    window.location = '/';
  }

  return (
    <div className="Req-box">
      <button className="Esc-button" onClick={() => handleEsc()}><BsX className='escapeButtonIcon'/></button>
      <p className="requestTitle"><span className="Light">Food</span><span className="Heavy">Flag</span></p>
      <div className='pressForHelp'>Press for help</div>
      <button className="Submit-button" onClick={() => onSubmit()}><span className='exclamationPoint'>!</span></button>

    </div>
  );
}
