import React, { useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import './FirstTimeLogin.css';

export default function FirstTimeLogin({ completeNewLogin }) {

    const { user, isAuthenticated } = useAuth0();
    const inputRef = useRef("");
    const API_URL = process.env.REACT_APP_AWS_BACKEND_URL;


    const handleSubmit = () => {

        if (inputRef.current.value.trim() === "") {
            window.alert("Error: Organization Name Cannot Be Empty");
        } else {
            if (isAuthenticated) {
                const newOrganization = {
                    "id": user.sub,
                    "name": inputRef.current.value
                }

                axios.post(`${API_URL}organizations/add/`, newOrganization)
                    .then(res => {
                        console.log(res);
                        completeNewLogin();
                    })
                    .catch(err => console.log(err));
            }

        }
    }

    return (
        <div className="FirstTimeLoginContainer">
            <div className="FirstTimeLoginHeader">
                <div className="FirstTimeLoginTitle">Enter Organization Name</div>
            </div>
            <div className="FirstTimeLoginName">Name</div>
            <input className="FirstTimeLoginInput" type="text" ref={inputRef}></input>
            <button className="FirstTimeLoginSubmitButton" onClick={() => handleSubmit()}>Submit</button>
        </div>
    );

}

