import React, { useRef} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BsX } from "react-icons/bs";
import axios from 'axios';
import './AddRestaurant.css';

export default function AddRestaurant({ setDisplay, updateRestaurants }) {

    const { user } = useAuth0();
    const inputRef = useRef("");
    const API_URL = process.env.REACT_APP_AWS_BACKEND_URL;


    const handleSubmit = () => {

        if (inputRef.current.value.trim() === "") {
            window.alert("Error: Restaurant Name Cannot Be Empty");
        } else {
            const newRestaurantInfo = { 
                "orgID" : user.sub,
                "name": inputRef.current.value 
            }


            axios.post(`${API_URL}organizations/addRestaurant/`, newRestaurantInfo)
                .then(res => {
                    console.log(res);
                    updateRestaurants();
                })
                .catch(err => console.log(err));
            
            setDisplay(false);
        }
    }

    return (
        <div className="addRestaurantContainer">

                <div className="addRestaurantTitle">Add Restaurant</div>
                <button className="addRestaurantEsc" onClick={() => setDisplay(false)}><BsX size={20} className="addRestaurantEscIcon"/></button>


                <div className="addRestaurantName">Enter Restaurant Name</div>
                <input type="text" ref={inputRef} className="addRestaurantInput"></input>
                <button className="addRestaurantSubmitButton" onClick={() => handleSubmit()}>+</button>

        </div>
    );

}

