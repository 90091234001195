import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IoMdClose, IoMdRefresh } from 'react-icons/io';
import AddTable from '../Components/AddTable';
import './AdminPage.css';
import { useAuth0 } from '@auth0/auth0-react';

export default function AdminPage() {

    const [requests, setRequests] = useState([]);
    const [tables, setTables] = useState([]);
    const [refreshRequests, setRefreshRequests] = useState(false);
    const [refreshTables, setRefreshTables] = useState(false);
    const [displayAddTable, setDisplayAddTable] = useState(false);
    const { user, isAuthenticated } = useAuth0();
    const restaurantId = (window.location.pathname).split('/').slice(2)[0];
    const API_URL = process.env.REACT_APP_AWS_BACKEND_URL;

    const handleEsc = () => {
        window.location = '/organizationPage';
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (isAuthenticated) {

                const info = {
                    "orgID": user.sub,
                    "restID": restaurantId
                }

                axios.post(`${API_URL}organizations/getRequests/`, info)
                    .then(res => {
                        setRequests(res.data);
                    })
                    .catch(err => console.log(err));

                setRefreshRequests(false);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [isAuthenticated, user, restaurantId, API_URL]);

    useEffect(() => {
        if (isAuthenticated) {

            const info = {
                "orgID": user.sub,
                "restID": restaurantId
            }

            axios.post(`${API_URL}organizations/getRequests/`, info)
                .then(res => {
                    setRequests(res.data);
                })
                .catch(err => console.log(err));

            setRefreshRequests(false);
        }
    }, [refreshRequests, isAuthenticated, restaurantId, user, API_URL])

    useEffect(() => {
        if (isAuthenticated) {

            const info = {
                "orgID": user.sub,
                "restID": restaurantId
            }

            axios.post(`${API_URL}organizations/getTables/`, info)
                .then(res => {
                    setTables(res.data)
                })
                .catch(err => console.log(err));

            setRefreshTables(false);
        }
    }, [isAuthenticated, restaurantId, user, API_URL]);

    useEffect(() => {
        if (isAuthenticated) {

            const info = {
                "orgID": user.sub,
                "restID": restaurantId
            }

            axios.post(`${API_URL}organizations/getTables/`, info)
                .then(res => {
                    setTables(res.data)
                })
                .catch(err => console.log(err));

            setRefreshTables(false);
        }
    }, [refreshTables, isAuthenticated, restaurantId, user, API_URL]);

    const deleteRequest = (req) => {
        const info = {
            "orgID": user.sub,
            "restID": restaurantId,
            "id": req._id
        }

        axios.post(`${API_URL}organizations/deleteRequest/`, info)
            .then(res => { console.log(res); setRefreshRequests(true) })
            .catch(err => console.log(err));
    }

    const refreshCode = (table) => {
        const newCode = generateCode();

        const codesInfo = {
            "orgID" : user.sub,
            "restID" : restaurantId,
            "oldCode": table.code,
            "newCode": newCode
        };

        const tableCode = {
            "code" : table.code
        }

        const newCodeInfo = {
            "code": newCode,
            "restaurantId": restaurantId,
            "organizationId": user.sub,
            "tableName": table.name
        }

        axios.post(`${API_URL}organizations/updateCode/`, codesInfo)
            .then(res => { console.log(res); setRefreshTables(true) })
            .catch(err => console.log(err));

        axios.post(`${API_URL}codes/delete/`, tableCode)
            .then(res => console.log(res))
            .catch(err => console.log(err));

        axios.post(`${API_URL}codes/add/`, newCodeInfo)
            .then(res => console.log(res))
            .catch(err => console.log(err));

    }

    const deleteTable = (table) => {
        const info = {
            "orgID": user.sub,
            "restID": restaurantId,
            "code": table.code
        }

        const code = {
            "code": table.code
        }

        if (isAuthenticated) {
            axios.post(`${API_URL}organizations/deleteTable/`, info)
                .then(res => { console.log(res); setRefreshTables(true) })
                .catch(err => console.log(err));

            axios.post(`${API_URL}codes/delete/`, code)
                .then(res => console.log(res))
                .catch(err => console.log(err));
        }
    }

    const handleAddTable = (name) => {

        if (name === "") {
            window.alert("Error: Table cannot have empty name");
            return;
        }
        if (isAuthenticated) {
            const code = generateCode();
            const newTableInfo = {
                "orgID" : user.sub,
                "restID" : restaurantId,
                "name": name,
                "code": code
            }

            const newCodeInfo = {
                "code": code,
                "restaurantId": restaurantId,
                "organizationId": user.sub,
                "tableName": name
            }

            axios.post(`${API_URL}organizations/addTable/`, newTableInfo)
                .then(res => { console.log(res); setRefreshTables(true); })
                .catch(err => { console.log(err); window.alert("Error: Failed to add new table"); });

            axios.post(`${API_URL}codes/add/`, newCodeInfo)
                .then(res => console.log(res))
                .catch(err => console.log(err));

            setDisplayAddTable(false);
        }
    }

    const generateCode = () => {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;

        for (var i = 0; i < 6; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }

    const shortenString = (s, w) => {
        if (s.length >= w) {
            s = s.substring(0, w - 1) + '...';
        }

        return s;
    }

    return (
        <div className='adminPageContainer'>
            {displayAddTable ? <AddTable setDisplay={setDisplayAddTable} addTable={handleAddTable} /> : ""}
            <div className='adminPageSideBar'>
                <div className='adminPageTitle'><span className="Light">Food</span><span className="Heavy">Flag</span></div>
                <div className='adminPageAddTableButton' onClick={() => setDisplayAddTable(true)}>
                    <div className='adminPageAddTableButtonTextContainer'>
                        <span className='adminPageAddTableButtonPlus'>+</span><span className='adminPageAddTableButtonAdd'>Add Table</span>
                    </div>
                </div>
            </div>
            <div className='adminPageMainContainer'>
                <div className='adminPageTopBar'>
                    <button className='adminPageReturnButton' onClick={handleEsc}>Return</button>
                </div>
                <div className="adminPageInfoContainer">
                    <div className='adminPageRequestsContainer'>

                        <div className='adminPageRequestsHeader'>
                            <span className='adminPageRequestsHeaderTitle'>Requests</span>
                            <span className='adminPageRequestsHeaderCount'>{requests.length}</span>
                        </div>
                        {requests.map((req, i) => {
                            return (<div className="adminPageRequestsRequest" key={i}>
                                <span className='adminPageRequestsRequestName'>{req.name}</span>
                                <div className='adminPageRequestsRequestCloseButton' onClick={() => deleteRequest(req)}>
                                    <IoMdClose size={26} className='adminPageRequestsRequestCloseButtonIcon' />
                                </div>
                            </div>);
                        })}
                    </div>
                    <div className='adminPageTablesContainer'>
                        <div className='adminPageTablesHeader'>
                            <span className='adminPageTablesHeaderTitle'>Tables</span>
                            <span className='adminPageTablesHeaderCount'>{tables.length}</span>
                        </div>
                        {tables.map((tab, i) => {
                            return (<div className="adminPageTablesTable" key={i}>
                                <div className='adminPageTablesTableName'>{shortenString(tab.name, 8)}</div>
                                <div className='adminPageTablesTableCode'>{tab.code}</div>
                                <div onClick={() => refreshCode(tab)} className='adminPageTablesTableRefreshButton'>
                                    <IoMdRefresh size={26} className='adminPageTablesTableRefreshButtonIcon' />
                                </div>
                                <div onClick={() => deleteTable(tab)} className='adminPageTablesTableCloseButton'>
                                    <IoMdClose size={26} className='adminPageTablesTableCloseButtonIcon' />
                                </div>
                            </div>);
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
